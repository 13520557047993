<template>
  <b-nav-item @click="onclickTheme">
    <!-- @click="onclickTheme" -->
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
// import { isUserLoggedIn } from '@/auth/utils'
// import store from '@/store'

export default {
  components: {
    BNavItem,
  },
  mounted() {
    // this.initData()
  },
  methods: {
    onclickTheme() {
      this.skin = this.isDark ? 'semi-dark' : 'dark'
      // window.web.user.changeSkin(this.isDark ? 0 : 1)
      // this.$store.dispatch('app/isSocketFunctionExist', 'changeSkin')
      //   .then(state => {
      //     if (state) window.socket.changeSkin(this.isDark ? 1 : 0)
      //   })
      // store.dispatch('api/setConfigUpdate', {
      //   theme: this.isDark ? 0 : 1,
      // })
    },

    // initData() {
    //   if (!isUserLoggedIn()) return
    //   setTimeout(() => {
    //     store.dispatch('api/getConfigData', { col: 'theme' })
    //       .then(response => {
    //         const { theme } = response.data.data
    //         this.skin = theme ? 'semi-dark' : 'dark'
    //       })
    //   }, 1000)
    // },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>

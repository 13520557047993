<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © 2012-{{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :to="{ name: 'admin-home' }"
      >曠世國際有限公司</b-link>
      <span class="d-none d-sm-inline-block"> , All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block mt-25">
      Powered by 曠世國際有限公司
      <a
        href="https://home.twhost.com.tw/"
        target="_blank"
        style="color:white"
      >
        網頁設計
      </a>
      <!-- <feather-icon
        icon="GitlabIcon"
        size="21"
        class="text-warning stroke-current"
      /> -->
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>

export const adminSidebar = () => {
  const user = [{
    title: '管理者列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/users.svg',
    route: { name: 'admin-user' },
    key: 'API.Admin.User',
  }]

  const branch = [{
    title: '分站管理列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/brand-admin.svg',
    route: { name: 'admin-branch' },
    key: 'API.Admin.Branch',
  }]

  const customer = [{
    title: '會員列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/customer.svg',
    route: { name: 'admin-customer' },
    key: 'API.Admin.Customer',
  }]

  const power = [{
    title: '權限列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/power.svg',
    route: { name: 'admin-power' },
    key: 'API.Admin.Power',
  }]

  const black = [{
    title: '黑名單列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/blacklist.svg',
    route: { name: 'admin-black' },
    key: 'API.Admin.Ban',
  }]

  const tag = [{
    title: '標籤列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/tag.svg',
    route: { name: 'admin-tag' },
    key: 'API.Admin.Tag',
  }]

  const payment = [{
    title: '金流列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/credit-card.svg',
    children: [
      {
        title: '金流商管理',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/settings.svg',
        route: { name: 'admin-payment-type' },
        key: 'API.Admin.ThirdPartyPayment',
      }, {
        title: '金流列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/list.svg',
        route: { name: 'admin-payment' },
        key: 'API.Admin.Payment',
      },
    ],
    // route: { name: 'admin-payment' },
  }]

  const domain = [{
    title: '網域管理',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/domain.svg',
    children: [
      {
        title: '網域商列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/domain-agent.svg',
        route: { name: 'admin-domain-agent' },
        key: 'API.Admin.DomainNameAgent',
      }, {
        title: '經銷商列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/domain-dealer.svg',
        route: { name: 'admin-domain-dealer' },
        key: 'API.Admin.DomainNameDealer',
      }, {
        title: '網域商品',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/price.svg',
        route: { name: 'admin-domain-price' },
        key: 'API.Admin.DomainNamePrice',
      },
    ],
  }]

  const cart = [{
    title: '購物車列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/cart-list.svg',
    route: { name: 'admin-cart' },
    key: 'API.Admin.Cart',
  }]

  const api = [{
    title: 'API管理',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/api.svg',
    children: [
      {
        title: '信箱API列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/api-mail.svg',
        route: { name: 'admin-api-mail' },
        key: 'API.Admin.MailApi',
      },
    ],
    // route: { name: 'admin-payment' },
  }]

  const meta = []

  const order = [{
    title: '訂單列表',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/order-list.svg',
    route: { name: 'admin-order' },
    key: 'API.Admin.Order',
  }]

  const service = [{
    title: '服務管理',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/service.svg',
    children: [
      {
        title: '網域列表',
        type: 1,
        icon: 'CastIcon',
        imgIcon: '/dashboard/admin/images/sideIcon/domain.svg',
        route: { name: 'admin-service-domain', params: { action: 'list' } },
        key: 'API.Admin.DomainName.Service',
      },
      // {
      //   title: '虛擬主機',
      //   type: 1,
      //   icon: 'CastIcon',
      //   imgIcon: '/dashboard/admin/images/sideIcon/domain-dealer.svg',
      //   route: { name: 'admin-service--vps' },
      //   key: 'API.Admin.DomainName.Service',
      // },
    ],
  }]

  const remark = []

  const quota = [{
    title: '點數紀錄',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/point.svg',
    route: { name: 'admin-quota' },
    key: 'API.Admin.Quota',
  }]

  return {
    user,
    branch,
    customer,
    power,
    black,
    tag,
    payment,
    domain,
    cart,
    api,
    meta,
    order,
    service,
    remark,
    quota,
  }
}

export const clientSidebar = () => {
  const home = [{
    title: '後台首頁',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/home.svg',
    route: { name: 'admin-home' },
  }, {
    title: '個人資料',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/dashboard/admin/images/sideIcon/profile.svg',
    route: { name: 'profile' },
  }]

  return {
    home,
  }
}
